import React from "react";
import "./Body.css";
import video_principal from "../../../design/video/Pronalce Oats-640.mp4";
import Slider from "../../About/slider-carousel/SlideshowMain";
import VideoPortafolio from "../../../widget//infinity_video/video";
import hoj_fresa from "../../../design/Productos/pds_avena_hojuela_fresa_1000g_w.png";
import chocotom from "../../../design/Productos/pronalce_chocotom_200g_w.png";
import hoj_vai from "../../../design/Productos/pds_avena_hojuela_500g_w.png";
import Natilla from "../../../design/Productos/pronalce_natilla_arequipe_300g_w.png";
import sab_tar_vai from "../../../design/Productos/PDS_saborizada_400g_vainilla.png";
import cake from "../../../design/Productos/PDS_cake_400g_vanilla.png";
import coco from "../../../design/Productos/pds_granola_coco_350g_w.png";
import insta from "../../../design/images/Insta.png";
import pank from "../../../design/Productos/pds_pancake_300g_w.png";
import PromoPopup from "../../../widget/popUp/Promo_popup";

import Trigo from "../../../design/Productos/04_Trigo.webp";
import Cebada from "../../../design/Productos/02_Cebada.webp";
import Arroz from "../../../design/Productos/06_Arroz.webp";
import Maiz from "../../../design/Productos/03_Maiz.webp";
import Avena from "../../../design/Productos/01_Avena.webp";

// Eventuales


const Body = () => {
  return (
    <div className="grid">
      <PromoPopup />
      <div className="video-principal" id="video_principal">
        <img className="img-video" src="/ruta/a/la/imagen.jpg" alt="" />
        <video
          className="video-avena"
          autoPlay
          loop
          muted
          playsInline
          src={video_principal}
        ></video>



        <div className="mensaje">
          <h1 className="mensaje">
            AQUÍ NACE <br />
            EL SABOR NATURAL
          </h1>
        </div>
      </div>



      <div className="procesos">
        <div className="grid-procesos">
          <div className="video-procesos" data-aos="fade-up">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/ZqoAokwSDlo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <div className="procesos-texto">
            <p></p>
          </div>
        </div>
      </div>

      <Slider />


      <div className="organic">
        <div className="img_organic">
          <img src={hoj_fresa} alt="" />
        </div>
        <div className="txt_organic">
          <h3 className="Subtitle_organic">SOMOS LA MEJOR FUENTE DE</h3>
          <h1 className="title_organic">CEREALES INTEGRALES</h1>
        </div>
      </div>

      <div className="galeria">
        <h1>Deliciosos productos de consumo</h1>

        <div className="grid_galeria_productos">
          <div className="items_productos">
            <div className="image_galeria galery1">
              <img src={sab_tar_vai} alt="Flavored Oats" />
            </div>
            <div className="text_galeria">
              <h2>Avena saborizada</h2>
              <p>
              ¡Deliciosa avena saborizada, perfecta para comenzar tu día con energía! Sin conservantes y de alta calidad.
              </p>
            </div>
          </div>
          <div className="items_productos">
            <div className="image_galeria galery2">
              <img src={coco} alt="Flavored Oats" />
            </div>
            <div>
              <h2>Granolas</h2>
              <p>
              Granola crujiente y nutritiva, ideal para un desayuno saludable. ¡Añade un toque de energía a tu mañana!
              </p>
            </div>
          </div>
          <div className="items_productos">
            <div className="image_galeria galery3">
              <img src={hoj_vai} alt="Flavored Oats" />
            </div>
            <div>
              <h2>Avena en Hojuela</h2>
              <p>
              Avena en hojuelas, ideal para preparar tus recetas favoritas. ¡Saludable y versátil!
              </p>
            </div>
          </div>
          <div className="items_productos">
            <div className="image_galeria galery4">
              <img src={Natilla} alt="Flavored Oats" />
            </div>
            <div>
              <h2>Natilla</h2>
              <p>
              Natilla tradicional, cremosa y deliciosa. ¡El postre perfecto para cualquier ocasión!
              </p>
            </div>
          </div>
          <div className="items_productos">
            <div className="image_galeria galery5">
              <img src={chocotom} alt="Flavored Oats" />
            </div>
            <div>
              <h2>Chocotom</h2>
              <p>
              Descubre el exquisito mundo del chocolate! Cada sorbo es una explosión de sabor rico y deleitante
              </p>
            </div>
          </div>
          <div className="items_productos">
            <div className="image_galeria galery6">
              <img src={pank} alt="Flavored Oats" />
            </div>
            <div>
              <h2>Pancakes</h2>
              <p>
              Mezcla para pancakes esponjosos y deliciosos. ¡Perfectos para un desayuno rápido y delicioso!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="Chocotom">
        <div className="img_chocotom">
          <img src={chocotom} alt="" />
        </div>
        <div className="txt_chocotom">
          <h1 className="title_chocotom">
          Energía y sabor
          <br></br> para el trabajo diario
          </h1>
          <h3 className="Subtitle_chocotom">
          ¡Descubre el exquisito mundo del chocolate! Cada sorbo es una explosión de sabor rico y deleitante.
          </h3>

          <a href="https://pronalce.myshopify.com/collections/industriales" class="button_chco">
            Ver Mas!
            </a>
        </div>
      </div>

      <div className="granos">
        <h1>Nuestros Cereales</h1>
        <div class="granos_elements">
          <div class="granos_product">
            <div class="image_granos">
              <img src={Avena} alt="Wheat" />
            </div>
            <h2>Avena</h2>
            <p>
            Avena de alta calidad ideal para alimentos saludables y nutritivos. ¡Descubre la mejor opción para tus productos!
            </p>
            <a href="https://pronalce.myshopify.com/collections/industriales" class="button_more">
              Ver Mas!
            </a>
          </div>
          <div class="granos_product">
            <div class="image_granos">
              <img src={Cebada} alt="Barley" />
            </div>
            <h2>Cebada</h2>
            <p>
            Cebada de alta calidad para alimentos balanceados. ¡Incorpora lo mejor en tus recetas!
            </p>
            <a href="https://pronalce.myshopify.com/collections/industriales" class="button_more">
            Ver Mas!
            </a>
          </div>
          <div class="granos_product">
            <div class="image_granos">
              <img src={Arroz} alt="Rice" />
            </div>
            <h2>Arroz</h2>
            <p>
            Arroz blanco seleccionado, ideal para una amplia variedad de productos alimenticios. ¡Garantiza calidad en cada grano!
            </p>
            <a href="https://pronalce.myshopify.com/collections/industriales" class="button_more">
            Ver Mas!
            </a>
          </div>
          <div class="granos_product">
            <div class="image_granos">
              <img src={Trigo} alt="Corn" />
            </div>
            <h2>Trigo</h2>
            <p>
            Trigo integral de primera, excelente para panes y pastas. ¡Asegura la frescura y el sabor en tus productos!
            </p>
            <a href="https://pronalce.myshopify.com/collections/industriales" class="button_more">
            Ver Mas!
            </a>
          </div>
          <div class="granos_product">
            <div class="image_granos">
              <img src={Maiz} alt="Maiz" />
            </div>
            <h2>Maiz</h2>
            <p>
            Maíz amarillo de máxima pureza, perfecto para snacks y alimentos procesados. ¡Lleva tus productos al siguiente nivel!
            </p>
            <a href="https://pronalce.myshopify.com/collections/industriales" class="button_more">
            Ver Mas!
            </a>
          </div>
        </div>
      </div>
      <div className="contact_us">
        <div className="txt_contact">
          <h1>
            ¡Contáctanos!
          </h1>
          <p>
            Lunes - Viernes 8:00 am - 5:00 pm
          </p>
          
          <p>
          Sabados y Domingos cerrado para centrarse en la familia.
          </p>
          <p>
          <span>Telefono: </span> (604)372 0874</p>
          <p>
          <span>Email: </span> AtencionalCliente@pronalce.com.co</p>
          <p>
          <span>Ubicados: </span>
         
            Itagüi - Antioquia - Colombia
          </p>
          
          <p>
            Carrera 50a # 43 - 13
          </p>
          
          <p>
          (604)372 0874
          </p>
          
          <p>
          Nit: 811022715-4
          </p>
        </div>
    
      </div>

    </div>
  );
};

export default Body;

import React from "react";


const Slider_style = {
  width: "100%",
  height: "100%",
  gridArea: "Slider_instagram_1",
  margin:"0px 0px 3% 0px",
  fontFamily: "Arial",
  background:"white",
};

const Slider_instagram_1 = () => {
  return (
    <div className="Slider_instagram_1" style={Slider_style}>
     <div
  loading="lazy"
  data-mc-src="c8eb8433-ba11-41c7-9a5a-d12df1f1c5cd#instagram"></div>
        
<script 
  src="https://cdn2.woxo.tech/a.js#63601e6eb09c2b8ca95de87d" 
  async data-usrc>
</script>

    </div>
   
  );
};

export default Slider_instagram_1;

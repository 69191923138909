import React from "react";
import "./loading.css"
import load_sur from "../../design/gif/Del_sur.gif";

const Loading = () => {
  return (
    <div className="loader" id="loader">

        
      <div className="loader-inner">
            <img className="logo" src={load_sur} alt="Pronalce del sur"/>
        </div>
     
    </div>
  );
};

export default Loading;

import React, { useEffect } from "react";
import "./nosotros.css";
import "./nosotros_slider.css";
import Glider from "glider-js"; // Importa Glider aquí

import nosotros1 from "../../../design/nosotros/Pronalce_crono_01.webp"
import nosotros2 from "../../../design/nosotros/Pronalce_crono_02.webp"
import nosotros3 from "../../../design/nosotros/Pronalce_crono_03.webp"
import nosotros4 from "../../../design/nosotros/Pronalce_crono_04.webp"


const Nosotros = () => {
    useEffect(() => {
        new Glider(document.querySelector(".carousel__lista"), {
            slidesToShow: 1,
            dots: ".carousel__indicadores",
            draggable: true,
            arrows: {
                prev: ".carousel__anterior",
                next: ".carousel__siguiente",
            },
      responsive: [
        {
          breakpoint: 775,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            itemWidth: 150,
            duration: 0.25,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            itemWidth: 150,
            duration: 0.25,
          },
        },
      ],
    });
    
    
    
  }, []);
    return (
        <div>
                <div className="grid_nosotros">
                    <div className="nosotros">
                        <h1>Nosotros</h1>
                    </div>

                        <div className="texto_nosotros">
                            <div className="texto_fondo_nosotros">
                            <h1>Nuestra Historia</h1>
                            <p>
                                <strong>PRONALCE</strong> es fundada en el año 2000, producto de un
                                sueño de familia… “construir una empresa industrial” Se pensó para
                                fabricar cereales y ser pionera en Colombia y en la región para
                                productos integrales, que conservaran lo mejor posible su naturaleza,
                                altos estándares de calidad y ante todo ofrecer a los futuros
                                consumidores <strong>CONFIANZA</strong>.
                            </p>
                            </div>
                        </div>

                        <div className="historia_tiempo">
                            <div className="carousel">
                            <div className="carousel__contenedor" id="item1">
                                <button aria-label="Anterior" className="carousel__anterior">
                                    <i className="fa-solid fa-chevron-left"></i>
                                </button>
                                <div className="carousel__lista">
                                <div className="carousel__elemento">
                                    <img src={nosotros1} alt="" />
                                    <p>
                                    <strong>2000: </strong> PRONALCE SA es fundada en el año 2000, producto de un sueño de familia…
                                    “construir una empresa industrial”
                                    </p>
                                </div>
                                <div className="carousel__elemento">
                                    <img src={nosotros2} alt="" />
                                    <p>
                                    <strong>2000: </strong> Se pensó para fabricar cereales y ser pionera en Colombia y en la región
                                    para productos integrales, que conservaran lo mejor posible su naturaleza, altos estándares de
                                    calidad y ante todo ofrecer a los futuros consumidores CONFIANZA
                                    </p>
                                </div>
                                <div className="carousel__elemento">
                                    <img src={nosotros3} alt="" />
                                    <p>
                                    <strong>2001: </strong> Ya constituida y con sus equipos de producción listos, se conforma el
                                    equipo de trabajo y para el mes de junio se genera la primera venta de 20 cajas de avena en
                                    hojuela de la marca PRONALCE DEL SUR en tarros por 400g.
                                    </p>
                                </div>
                                <div className="carousel__elemento">
                                    <img src={nosotros4} alt="" />
                                    <p>
                                    <strong>2011... </strong> Luego de trabajar muy fuerte en el crecimiento en ventas y desarrollo
                                    tecnológico, la empresa tiene tres logros relevantes para este momento.
                                    </p>
                                </div>
                                </div>

                                <button aria-label="Siguiente" className="carousel__siguiente">
                                    <i className="fa-solid fa-chevron-right"></i>
                                </button>
                            </div>
                            <div role="ta" className="carousel__indicadores"></div>
                            </div>
                        </div>

                        <div className="empleados">
                            <div className="texto_empleados">
                            <h1>NUESTRA PLANTA</h1>
                            <h1>ESTA LISTA</h1>
                            <p>
                                “Si está buscando ingredientes naturales y de alta calidad para sus productos alimentarios, no dude en
                                ponerse en contacto con nosotros. Estamos comprometidos en ayudar a nuestros clientes a llevar su
                                negocio al siguiente nivel”
                            </p>
                            </div>
                        </div>

                        <div className="pro_colombia">
                            <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/ZqoAokwSDlo"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            ></iframe>
                        </div>
                </div>
   
               
        </div>
    );
};

export default Nosotros;
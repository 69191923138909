import React from 'react';
import './Exportaciones.css';
import expo1 from '../../../design/exportaciones/Exporta_pallet.png';

const exportaciones = () => {
  return (
    <div>
      <div className="grid-exporta">
    <div className="exporta">
        <h1>Exportaciones</h1>
    </div>

    <div className="expo_mapa">
        <div className="grid_mapa">
            <div className="mapa_img">

                <div className="estiba_img">
                    <h1>Nuestro enfoque</h1>
                    <p>Enfocados en el éxito, atendemos diversos mercados tanto a nivel nacional como internacional. Nuestra capacidad para generar productos innovadores y de alto valor agregado nos distingue en la industria. Con un compromiso constante con la excelencia, estamos listos para llevar tus proyectos al siguiente nivel. ¡Descubre cómo nuestro enfoque puede impulsar tu negocio a gran escala!</p>
                </div>
                <img src={  expo1 } alt="" />
            </div>


            <div className="mapa_txt">
                <p> </p>
                <div className="texto">
                    <p>Con un compromiso constante hacia la excelencia y una dedicación incansable hacia la mejora continua, estamos completamente preparados para llevar tus proyectos al siguiente nivel. Nuestro enfoque meticuloso y orientado a resultados no solo busca alcanzar tus objetivos, sino superarlos con creces. Descubre cómo nuestra experiencia y pasión pueden impulsar tu negocio hacia nuevas alturas de éxito y rentabilidad. ¡Permítenos ser tu socio estratégico en el camino hacia el crecimiento y la excelencia empresarial!</p>
                </div>
            </div>

        </div>

    </div>
</div>

    </div>
  )
};

export default exportaciones

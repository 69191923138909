import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./TrabajaConNosotros.css"; // Importa el archivo CSS
import img_convocatorias from "../../../design/images/Convocatoria.png";
import hoja from "../../../design/ico/Planta_Pronalce2.png";

const ConvocatoriasTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Reemplaza 'ruta/a/tu/archivo.csv' con la ruta correcta de tu archivo CSV
    fetch("/convocatorias.csv")
      .then((response) => response.text())
      .then((csvText) => {
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            setData(results.data);
          },
        });
      });
  }, []);

  return (
    <div className="convocatorias">
      <div className="convocatorias-container">
        <div className="convocatorias-title">
          
          <h1><img src={hoja}></img>rabaja con Nosotros</h1>
        </div>
        <div className="convocatorias-img">
          <img src={img_convocatorias}></img>
        </div>
      </div>

      <div className="convocatorias-info">
        <p>
          En esta sección podrás encontrar las últimas convocatorias de
          Pronalce, así como información sobre los perfiles académicos y
          experiencia requeridos.
        </p>
        <p>
          Envia tu hoja de vida al correo{" "}
          <a href="mailto:seleccion@pronalce.com.co">
            seleccion@pronalce.com.co
          </a>
          , anunciando en el asunto la vacante a la cual aplicar. Ejemplo:
          Operacion de Produccion - Hdv
        </p>
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>VACANTE</th>
              <th>DESCRIPCIÓN</th>
              <th>FECHA DE INICIO</th>
              <th>FECHA DE CIERRE</th>
              <th>PERFIL-ACADÉMICO</th>
              <th>PERFIL-EXPERIENCIA</th>
              <th>MÁS INFORMACIÓN</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{row["VACANTE"]}</td>
                <td>{row["DESCRIPCIÓN"]}</td>
                <td>{row["FECHA DE INICIO"]}</td>
                <td>{row["FECHA DE CIERRE"]}</td>
                <td>{row["PERFIL-ACADÉMICO"]}</td>
                <td>{row["PERFIL-EXPERIENCIA"]}</td>
                <td className="button-convocatoria">
                  <a
                    href={row["MÁS INFORMACIÓN"]}  target="_blank" rel="noopener noreferrer">
                    {`Computrabajo`}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConvocatoriasTable;

import React from "react";
import './bebidas.css';

import Claim_proteina from "../../../design/del_sur/Elements/Claim_Protein.gif";
import Claim_colesterol from "../../../design/del_sur/Elements/Claim_colesterol.gif";
import Claim_fiber from "../../../design/del_sur/Elements/Claim_fiber.gif";
import bebida_fam from "../../../design/del_sur/Elements/Bebidas_family.png"

const Bebidas = () => {
    return (
        <div>
            <div className="primary_bebidas">
    <div className="txt_bebidas">
        <h1 className="title_bebidas">
            Bebidas
        </h1>
    </div>
    <div className="img_bebidas">
        <img src="." alt="" />
    </div>
</div>

<div className="tlt_bebidas">
    <h1>
        100% Avena integral
    </h1>
</div>

<div className="avenas_bebidas">
    <div className="img_avenas_bebidas">
        <img src={bebida_fam}alt="bebidas pronalce del sur" />
    </div>

    <div className="txt_avenas_bebidas">
        <h2>
            Bebidas de Avena con sabores
        </h2>
        <br />

        <div className="sabores">
            <div className="Preentaciones">
                <h3>
                    Presentaciones:
                </h3>
                <h3>
                    <b>250g - 400g</b>
                </h3>
            </div>
            <div className="sabores_txt">
                <h3>
                    Sabores:
                </h3>
                <h3>
                    <b>Vainilla - Fresa- Mora - Canela - Tropical - Piña</b>
                </h3>
            </div>
            <button target="_blank" className="boton_bebidas" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
                <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
            </button>
        </div>

        <div className="nutricion">
            <div className="nutricion1">
                <div>
                    <img src={Claim_proteina} alt="nutricion" />
                </div>
                <div>
                    <p><b>Excelente<br /> fuente de proteina</b></p>
                </div>
            </div>
            <div className="nutricion2">
                <div>
                    <img src={Claim_colesterol} alt="Colesterol" />
                </div>
                <div>
                    <p><b>Naturalmente es libre de colesterol</b></p>
                </div>
            </div>
            <div className="nutricion3">
                <div><img src={Claim_fiber} alt="fibra" /></div>
                <div>
                    <p><b>Excelente <br /> fuente de fibra</b></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="bebidas_instagram">
    <h2>
        Enriquecidas con vitaminas A, B1, B2, <br />
        B6, B12, C, D, Calcio, Hierro y Zinc
    </h2>
    <div key="InstagramGalleryWrapper" className="ft" id="ftjbbn77z"></div>
    <script src="https://cdn.fouita.com/widgets/0xd8859.js"></script>
</div>

        </div>
    )
}   

export default Bebidas;
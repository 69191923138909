import React from "react";
import './mezclas_listas.css';

import Claim_Proteina from "../../../design/del_sur/Elements/Claim_Protein.gif";
import Claim_colesterol from "../../../design/del_sur/Elements/Claim_colesterol.gif";
import Claim_fiber from "../../../design/del_sur/Elements/Claim_fiber.gif";
import Panckey from "../../../design/del_sur/Elements/Mezclas_pancake.png";
import Natilla from "../../../design/del_sur/Elements/Mezclas_natillas.png";
const Mezclas_listas = () => {
    
    return (
        <div>
            <div className="primary_mezclas">
    <h1 className="title_mezclas">
        Mezclas <br /> Listas
    </h1>
</div>



<div className="tlt_mezclas">
    <h1>
        100% Avena integral
    </h1>
</div>

<div className="avena_pancake">
    <div className="txt_avenas_pancackes">
        <h2>
            Avena en hojuelas, <br /> natural
        </h2>
        <br />
        <h3>
            Presentaciones:
        </h3>
        <h3>
            <b>300g</b>
        </h3>
        <button target="_blank" className="boton_avena" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
            <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
        </button>
        <div className="nutricion">
            <div className="nutricion1">
                <div>
                    <img src={Claim_Proteina} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de proteina</p>
                </div>
            </div>
            <div className="nutricion2">
                <div>
                    <img src={Claim_colesterol} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de colesterol</p>
                </div>
            </div>
            <div className="nutricion3">
                <div>
                    <img src={Claim_fiber} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de fibra</p>
                </div>
            </div>
        </div>
    </div>
    <div className="img_avenas_pancake">
        <img src={Panckey}  alt="hojuelas pronalce del sur" />
    </div>
</div>

<div className="avenas_mezclas">
    <div className="img_avenas_mezclas">
        <img src={Natilla} alt="hojuelas pronalce del sur" />
    </div>
    <div className="txt_mezclas">
        <h2>
            Natilla Tradicional & Arequipe
        </h2>
        <br />
        <div className="sabores">
            <div className="Preentaciones">
                <h3>
                    Presentaciones:
                    <b> 300g </b>
                </h3>
            </div>
            <div>
                <b>
                    <button target="_blank" className="boton_mezclas" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
                        <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
                    </button>
                </b>
            </div>
        </div>
    </div>
</div>

        </div>
    )       
}

export default Mezclas_listas
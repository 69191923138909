import React from 'react';
import './politicas.css';
import politicas from "../../../design/politicas/PoliticasI2.png";

const Politicas = () => {
  return (
    <section>
      <span>IN AYC-27 PROPOSITO, POLITICA INTEGRAL EDICIÓN 110722-0</span>
      <h1>POLITICAS INTEGRALES</h1> 

      <img className="politicas-img1" src={politicas} alt="" />

      <div className="one-politica">
        <div className="title-politica">
          <h1>Misión</h1>
        </div>
        <div className="text-politica">
          <p>
            Gracias a nuestra <b>experiencia</b>, somos un <b>desarrollador confiable</b> de soluciones alimenticias saludables, principalmente a base de cereales con adiciones de frutas, frutos y semillas, que nos permiten cumplir lo que los consumidores quieren!
          </p>
          <h3>¡Pronalce te alimenta bien!</h3>
        </div>
        <p id="p2">
          - Desarrollador: Incluye negocios con marca propia, maquilas y la industria
          <br />
          - Saludables: Incluye nutrición
          <br />
          - Innovador: Incluye valor agregado a clientes y consumidores
        </p>               
      </div>

      <div className="two-politica">
        <div className="title-politica">
          <h1>PROPOSITO AL 2025</h1>
        </div>
        <div className="text-politica">
          <p>
            Seguiremos siendo una empresa <b>confiable</b>; enfocando nuestro esfuerzo en la <b>rentabilidad y competitividad</b>, con altos estándares de calidad, <b>CRECIENDO</b> en los mercados nacionales e internacionales.
          </p>
        </div>
      </div>

      <div className="tree-politica">
        <div className="title-politica">
          <h1>POLITICA INTEGRADA DE CALIDAD, AMBIENTAL, SST, INOCUIDAD.</h1>
        </div>
        <div className="text-politica">
          <p>
            En <b>PRONALCE S.A.</b> con la política integrada nos comprometemos con los clientes, medio ambiente, empleados y partes interesadas para ofrecerles tranquilidad, confianza y solidez, porque cumplimos con la legislación aplicable y con los estándares que ellos desean, apoyándonos en la norma técnica ISO 9001; Para ello nos basamos en:
            <br />
            <b>SATISFACCIÓN DEL CLIENTE:</b> Ofrecemos a nuestros clientes y consumidores productos y servicios de alta calidad y entrega oportuna.
            <br />
            <b>CALIDAD E INOCUIDAD:</b> Nos comprometemos a fabricar productos que cumplan con los requisitos legales y normativos, tanto de calidad como de inocuidad.
            <br />
            <b>MEDIO AMBIENTE:</b> Nos comprometemos a velar por el uso racional de los recursos naturales; manejo, disposición y reducción de residuos sólidos, de igual forma se compromete a acatar los requerimientos legislativos.
            <br />
            <b>SEGURIDAD Y SALUD DE LOS EMPLEADOS:</b> Identificamos los peligros, evaluamos y valoramos los riesgos y establecimos los controles necesarios brindando a nuestros empleados seguridad en el trabajo fomentando cultura del cuidado mutuo para prevenir accidentes de trabajo y enfermedades laborales.
            <br />
            <b>SOLIDEZ:</b> Rentabilidad que garantice el cumplimiento de todas nuestras obligaciones y los requerimientos económicos para la permanencia en el mercado.
          </p>
          <h3>“DISFRUTAMOS LO QUE HACEMOS Y LO HACEMOS CADA VEZ MEJOR”</h3>
        </div>
      </div>
    </section>
  );
};

export default Politicas;

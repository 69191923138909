import React, { useState, useEffect, useMemo } from 'react';
import Papa from 'papaparse';
import ImageCarousel from './ImageCarousel/ImageCarousel';
import './Pdf_run.css';

// Importar imágenes de manera dinámica
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('./img_rit', false, /\.(jpg)$/));

const App = () => {
  const [cedula, setCedula] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [error, setError] = useState('');
  const [showCarousel, setShowCarousel] = useState(false);

  useEffect(() => {
    Papa.parse('/doc/validacion.csv', {
      download: true,
      header: true,
      complete: (result) => {
        setAllowedUsers(result.data);
      },
      error: (err) => setError('Error al cargar el archivo CSV.'),
    });
  }, []);

  // Usar useMemo para optimizar la filtración de nombres
  const filteredNames = useMemo(() => 
    allowedUsers.filter((user) => 
      user.nombre && user.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    ), [searchTerm, allowedUsers]);

  const handleViewCarousel = () => {
    const user = allowedUsers.find(
      (u) => u.nombre === selectedName && u.cedula === cedula
    );

    if (user) {
      setShowCarousel(true);
      setError(''); // Limpiar errores
    } else {
      setError('Cédula no coincide con el nombre seleccionado. Intenta de nuevo.');
    }
  };

  const handleCloseCarousel = () => {
    setShowCarousel(false);
  };

  return (
    <div className="container">
      <h1>Reglamento Interno del Trabajo</h1>
      <input 
        type="text" 
        placeholder="Busca tu nombre" 
        className="input"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {searchTerm && (
        <ul className="dropdown-list">
          {filteredNames.map((user, index) => (
            <li 
              key={index} 
              className="dropdown-item"
              onClick={() => {
                setSelectedName(user.nombre);
                setSearchTerm(user.nombre);
              }}
            >
              {user.nombre}
            </li>
          ))}
        </ul>
      )}
      <input 
        type="text" 
        placeholder="Cédula" 
        className="input"
        value={cedula} 
        onChange={(e) => setCedula(e.target.value)} 
      />
      <button className="button" onClick={handleViewCarousel}>Ingresar</button>
      {error && <p className="error-message">{error}</p>}

      {showCarousel && (
        <ImageCarousel images={images} onClose={handleCloseCarousel} />
      )}
    </div>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import './ImageCarousel.css';

const ImageCarousel = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState([]);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false); // Nuevo estado para manejar el zoom
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (images && images.length > 0) {
      setLoadedImages(images);
      setImageLoadError(false);
    } else {
      setImageLoadError(true);
    }
  }, [images]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === loadedImages.length - 1 ? 0 : prevIndex + 1
    );
    setIsZoomed(false); // Reiniciar el zoom al cambiar de imagen
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? loadedImages.length - 1 : prevIndex - 1
    );
    setIsZoomed(false); // Reiniciar el zoom al cambiar de imagen
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
    setIsZoomed(false); // Reiniciar el zoom al hacer clic en una miniatura
  };

  const handleImageError = (e) => {
    e.target.src = '../../../../design/ico/Logo_pronalce_sq.jpg'; // Imagen de respaldo si falla
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed); // Alternar el estado del zoom
  };

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setZoomPosition({ x, y });
  };

  return (
    <div className="carousel-overlay">
      <div className="carousel-content">
        <button className="close-button" onClick={onClose}>X</button>

        {imageLoadError ? (
          <p>Error al cargar imágenes.</p>
        ) : (
          loadedImages.length > 0 && (
            <div className="carousel-container">
              <button className="prev-button" onClick={handlePrev}>
                {'<'}
              </button>
              <div
                className="carousel-slide"
                onMouseMove={isZoomed ? handleMouseMove : null}
                style={{
                  overflow: isZoomed ? 'scroll' : 'hidden', // Permitir scroll solo si está haciendo zoom
                }}
              >
                <img
                  src={loadedImages[currentIndex]}
                  alt={`Slide ${currentIndex}`}
                  className="carousel-image"
                  style={{
                    transform: isZoomed ? 'scale(2)' : 'scale(1)', // Zoom con clic
                    cursor: isZoomed ? 'zoom-out' : 'zoom-in', // Cambiar el cursor en función del zoom
                    transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`, // Zoom basado en el cursor
                  }}
                  onClick={toggleZoom} // Manejar clic para alternar el zoom
                  onError={handleImageError}
                />
              </div>
              <button className="next-button" onClick={handleNext}>
                {'>'}
              </button>
              <div className="thumbnail-gallery">
                {loadedImages.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index}`}
                    className={`thumbnail-image ${index === currentIndex ? 'active' : ''}`}
                    onClick={() => handleThumbnailClick(index)}
                    onError={handleImageError}
                  />
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ImageCarousel;

import React from 'react'
import './Industria.css'
import inds_img_1 from '../../../design/industria/industria_costales.png'
import grano1 from '../../../design/images/granos_1.webp'
import grano2 from '../../../design/images/granos_2.webp'
import grano3 from '../../../design/images/granos_3.webp'
import grano4 from '../../../design/images/granos_4.webp'
import grano5 from '../../../design/images/granos_5.webp'


const industria = () => {
  return (
    <div>
      <div className="grid-industria">
  <div className="industria">
    <h1>Industria</h1>
  </div>
  <div className="industria_img">
    <div className="grid_industria">
      <img src={ inds_img_1 }  alt=""/>
      <div className="industria_txt">
        <p>
          En nuestra empresa, nos enorgullece ofrecer soluciones innovadoras y de alta calidad para la industria alimentaria. Producimos y comercializamos productos naturales a base de cereales como avena, cebada, trigo, maíz, arroz y quinua, procesados para obtener hojuelas y harina de la más alta calidad.
        </p>
      </div>
    </div>
  </div>

  <div className="industria_img2">
    <p>
      Además, ofrecemos una amplia variedad de mezclas de cereales ideales para una variedad de productos, desde granolas hasta mezclas para avenas saborizadas, mezclas para pancakes, mezclas de hojuelas con frutas y mezclas para galletas. Todos nuestros productos están diseñados para satisfacer las necesidades de la industria alimentaria y ayudar a nuestros clientes a destacarse en un mercado cada vez más competitivo.
    </p>
  </div>

  <div className="MateriaPrima">
    <div
      className="materiaPrima hoejulas"
      alt="materia prima - granos -
               maiz - arroz - cebada - avena"
    >
      <div className="materiaPrima-hoejulas">
        <div className="textoMateriaPrima">
          <p><b> EL CEREAL QUE NECESITES</b></p>
        </div>

        <div className="imgMateriales">
          <div className="materiasPrimasItems">
            <div className="materiasPrimas1">
            <img src={ grano1 }  alt=""/>
            </div>
            <p>AVENA</p>
          </div>
          <div className="materiasPrimasItems">
            <div className="materiasPrimas2">
              <img src={ grano2 }  alt=""/>
            </div>
            <p>ARROZ</p>
          </div>
          <div className="materiasPrimasItems">
            <div className="materiasPrimas3">
            <img src={ grano3 }  alt=""/>
            </div>
            <p>TRIGO</p>
          </div>
          <div className="materiasPrimasItems">
            <div className="materiasPrimas4">
            <img src={grano4}  alt=""/>
            </div>
            <p>CEBADA</p>
          </div>
          <div className="materiasPrimasItems">
            <div className="materiasPrimas5">
            <img src={ grano5 }  alt=""/>
            </div>
            <p>MAÍZ</p>
          </div>
        </div>
      </div>
    </div>
        <div className="textoMateriaPrima2">
          <p>
            Nuestros Productos son obtenidos de la selcción, limpieza coccion,
            laminacion y molienda del <b>grano integral</b>
          </p>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default industria

import React from 'react';
import './maquila.css';
import maqui_img1 from '../../../design/maquila/ma-kill-a.webp';
import Slider_instagram_1 from '../../../widget/slider_instagram';

const Maquila = () => {
    return (
        <div>
            <div className="grid-maquila">
  <div className="maquila">
    <h1>Maquila</h1>
  </div>

  <div className="aliados">
    <div className="aliados_grid">
      <div className="texto_aliados">
        <h1 className="titulo">Somos tu Aliado</h1>
        <p>En un mercado cada vez más competitivo, nos destacamos por la calidad y seguridad de nuestros productos. Cumplimos con los más altos estándares de calidad e inocuidad alimentaria, ofreciendo a nuestros clientes la tranquilidad que necesitan para prosperar en su negocio. Únete a nosotros y descubre cómo podemos ayudarte a destacar en el mercado actual.</p>
      </div>
      <div className="imagen_aliados">
        <p> </p>
      </div>
    </div>
  </div>

  <div className="aliados2">
    <div className="aliados_grid2">
      <div className="imagen_aliados2">
        <img src={ maqui_img1} alt="" />
      </div>
      <div className="texto_aliados2">
        <p>Centramos nuestra atención en atender una amplia gama de mercados, tanto a nivel nacional como internacional. Contamos con la capacidad comprobada de desarrollar productos innovadores y de alto valor agregado. Nuestro enfoque se centra en ofrecer soluciones de calidad que se adapten a las necesidades de nuestros clientes. Únete a nosotros y descubre cómo podemos colaborar para impulsar tu negocio hacia nuevas fronteras.</p>
      </div>
    </div>
  </div>

    <div className="slider_maquila">
      <Slider_instagram_1 />
      </div>
    <div className="blanco">
    </div>
</div>

        </div>
    )
}

export default Maquila;
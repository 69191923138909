import React from 'react';
import './harinas.css';

import Claim_Proteina from "../../../design/del_sur/Elements/Claim_Protein.gif";
import Claim_colesterol from "../../../design/del_sur/Elements/Claim_colesterol.gif";
import Claim_fiber from "../../../design/del_sur/Elements/Claim_fiber.gif";
import Claim_grasa_saturada from "../../../design/del_sur/Elements/Claim_grasa_saturada.gif";
import Harinas_natural from "../../../design/del_sur/Elements/Harinas_natural.png";

const Harinas = () => {
    return (
        <div>
            <div className="primary_harinas">
    <div className="txt_harinas">
        <h1 className="title_harinas">
            Harinas
        </h1>
    </div>
    <div className="img_harinas">
        <img src="." alt="" />
    </div>
</div>

<div className="tlt_harinas">
    <h1>
        100% Avena integral
    </h1>
</div>

<div className="avenas_harinas">
    <div className="txt_avenas_harinas">
        <h2>
            Avena en harina, <br /> sabor natural
        </h2>
        <br />
        <h3>
            Presentaciones:
        </h3>
        <h3>
            <b>250g</b>
        </h3>
        <button target="_blank" className="boton_harinas" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
            <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
        </button>
        <div className="nutricion">
            <div className="nutricion1">
                <div>
                    <img src={Claim_Proteina} alt="Harinas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de proteina</p>
                </div>
            </div>
            <div className="nutricion2">
                <div>
                    <img src={Claim_colesterol} alt="Harinas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de colesterol</p>
                </div>
            </div>
            <div className="nutricion3">
                <div>
                    <img src={Claim_fiber} alt="Harinas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de fibra</p>
                </div>
            </div>
            <div className="nutricion4">
                <div>
                    <img src={Claim_grasa_saturada} alt="Harinas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de grasas saturada</p>
                </div>
            </div>
        </div>
    </div>
    <div className="img_avenas_harinas">
        <img src={Harinas_natural} alt="bebidas pronalce del sur" />
    </div>
</div>

        </div>
    );
};

export default Harinas
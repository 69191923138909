import React from 'react';
import './granolas.css';

import Claim_Proteina from "../../../design/del_sur/Elements/Claim_Protein.gif";
import Claim_colesterol from "../../../design/del_sur/Elements/Claim_colesterol.gif";
import Claim_fiber from "../../../design/del_sur/Elements/Claim_fiber.gif";
import Claim_grasa_saturada from "../../../design/del_sur/Elements/Claim_grasa_saturada.gif";
import Granolas_coco from "../../../design/del_sur/Elements/Granolas_coco.png";
import Granolas_frutos from "../../../design/del_sur/Elements/Granolas_frutos.png";
import Granolas_Choco from "../../../design/del_sur/Elements/Granolas_chocolate.png";


const Granolas = () => {
    
    return (
        <div >
           <div className="primary_granola">
    <div className="txt_granola">
        <h1 className="title_granola">
            Granolas
        </h1>
    </div>
    <div className="img_granola">
        <img src="." alt="" />
    </div>
</div>

<div className="tlt_granola">
    <h1>
        100% Avena integral
    </h1>
</div>

<div className="avenas_granola">
    <div className="txt_avenas_granola">
        <h2>
            Granola de, <br /> coco pasas
        </h2>
        <br />
        <h3>
            Presentaciones:
        </h3>
        <h3>
            <b>35g - 200g - 350g</b>
        </h3>
        <button target="_blank" className="boton_granola" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
            <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
        </button>
        <div className="nutricion">
            <div className="nutricion1">
                <div>
                    <img src={Claim_Proteina} alt="" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de proteina</p>
                </div>
            </div>
            <div className="nutricion2">
                <div>
                    <img src={Claim_colesterol} alt="" />
                </div>
                <div>
                    <p>Naturalmente es libre de colesterol</p>
                </div>
            </div>
            <div className="nutricion3">
                <div>
                    <img src={Claim_fiber} alt="" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de fibra</p>
                </div>
            </div>
            <div className="nutricion4">
                <div>
                    <img src={Claim_grasa_saturada} alt="" />
                </div>
                <div>
                    <p>Naturalmente es libre de grasas saturada</p>
                </div>
            </div>
        </div>
    </div>
    <div className="img_avenas_granola">
        <img src={Granolas_coco} alt="bebidas pronalce del sur" />
    </div>
</div>

<div className="avenas_granola_frutos">
    <div className="img_avenas_granola">
        <img src={Granolas_frutos} alt="bebidas pronalce del sur" />
    </div>
    <div className="txt_avenas_granola">
        <h2>
            Granola frutos rojos
        </h2>
        <br />
        <h3>
            Presentaciones:
        </h3>
        <h3>
            <b>35g - 350</b>
        </h3>
        <button target="_blank" className="boton_granola_frutos" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
            <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
        </button>
        <div className="nutricion">
            <div className="nutricion1">
                <div>
                    <img src={Claim_Proteina} alt="" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de proteina</p>
                </div>
            </div>
            <div className="nutricion2">
                <div>
                    <img src={Claim_colesterol} alt="" />
                </div>
                <div>
                    <p>Naturalmente es libre de colesterol</p>
                </div>
            </div>
            <div className="nutricion3">
                <div>
                    <img src={Claim_fiber} alt="" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de fibra</p>
                </div>
            </div>
            <div className="nutricion4">
                <div>
                    <img src={Claim_grasa_saturada} alt="" />
                </div>
                <div>
                    <p>Naturalmente es libre de grasas saturada</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="tlt_granola">
    <h1>
        100% Avena integral
    </h1>
</div>

<div className="avenas_granola_choco">
    <div className="txt_avenas_granola_choco">
        <h2>
            Granola Chocolate
        </h2>
        <br />
        <h3>
            Presentaciones:
        </h3>
        <h3>
            <b>35g - 200g - 350g</b>
        </h3>
        <button target="_blank" className="boton_granola_choco" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
            <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
        </button>
        <div className="nutricion">
            <div className="nutricion1">
                <div>
                    <img src={Claim_Proteina} alt="" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de proteina</p>
                </div>
            </div>
            <div className="nutricion2">
                <div>
                    <img src={Claim_colesterol} alt="" />
                </div>
                <div>
                    <p>Naturalmente es libre de colesterol</p>
                </div>
            </div>
            <div className="nutricion3">
                <div>
                    <img src={Claim_fiber} alt="" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de fibra</p>
                </div>
            </div>
            <div className="nutricion4">
                <div>
                    <img src={Claim_grasa_saturada} alt="" />
                </div>
                <div>
                    <p>Naturalmente es libre de grasas saturada</p>
                </div>
            </div>
        </div>
    </div>
    <div className="img_avenas_granola_choco">
        <img src={Granolas_Choco} alt="" />
    </div>
</div>

        </div>
    )
};

export default Granolas;
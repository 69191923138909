import React, { useState, useEffect } from "react";
import "./popup.css"; // Asegúrate de tener el CSS importado
import Almin from "../../design/images/Almin.png";
import adexa from "../../design/images/adexa.png";
import pancardyl from "../../design/images/pancardyl.jpg";
import isimo from "../../design/images/isimo.png";
import merqueo from "../../design/images/logo-merqueo-01 .png";
import euro from "../../design/images/logoEurosupermercado.png";
import exito from "../../design/images/Logo_Exito_colombia.png";

const Popup = () => {
  const [mostrar, setMostrar] = useState(false);

  const togglePopup = (event) => {
    event.stopPropagation();
    setMostrar(!mostrar);
  };

  const cerrarPopupExternamente = (event) => {
    if (!event.target.closest(".popup") && !event.target.closest("#miBoton")) {
      setMostrar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", cerrarPopupExternamente);
    return () => {
      document.removeEventListener("click", cerrarPopupExternamente);
    };
  }, []);

  return (
    <div>
      <button id="miBoton" className="boton-pequeno" onClick={togglePopup}>
        Encuentranos!
      </button>
      <div id="miPopup" className={`popup ${mostrar ? "mostrar" : ""}`}>
        <ul>
          <li>
            <img src={Almin} alt="Almin" />
          </li>
          <li>
            <img src={adexa} alt="adexa" />
          </li>
          <li>
            <img src={pancardyl} alt="pancardyl" />
          </li>
          <li>
            <img src={isimo} alt="isimo" />
          </li>
          <li>
            <img src={merqueo} alt="merqueo" />
          </li>
          <li>
            <img src={euro} alt="euro" />
          </li>
          <li>
            <img src={exito} alt="Exito" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Popup;

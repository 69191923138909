import React from 'react';

import './hojuela.css';
import Claim_Protein from '../../../design/del_sur/Elements/Claim_Protein.gif';
import Claim_grasa_saturada from '../../../design/del_sur/Elements/Claim_grasa_saturada.gif';
import Claim_fiber from '../../../design/del_sur/Elements/Claim_fiber.gif';
import Claim_colesterol from '../../../design/del_sur/Elements/Claim_colesterol.gif';
import Hojuelas_natural from '../../../design/del_sur/Elements/Hojuelas_natural.png';
import Hojuelas_fresa from '../../../design/del_sur/Elements/Hojuelas_fresa.png';
import Hojuelas_vainilla from '../../../design/del_sur/Elements/Hojuelas_vainilla.png';
import Hojuelas_banano from '../../../design/del_sur/Elements/Hojuelas_banano.png';


const Hojuelas = () => {
    return (
        <div>
            

            <div className="primary_hojuela">
    <div className="txt_hojuela">
        <h1 className="title_hojuela">
            Hojuelas
        </h1>
    </div>
    <div className="img_hojuela">
        <img src="" alt="" />
    </div>
</div>

<div className="tlt_hojuela">
    <h1>
        100% Avena integral
    </h1>
</div>

<div className="avenas_hojuela">
    <div className="txt_avenas_hojuelas">
        <h2>
            Avena en hojuelas, <br /> natural
        </h2>
        <br />
        <h3>
            Presentaciones:
        </h3>
        <h3>
            <b>200g - 250g - 500g</b>
        </h3>

        <button target="_blank" className="boton_avena" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
            <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
        </button>

        <div className="nutricion">
            <div className="nutricion1">
                <div>
                    <img src={Claim_Protein } alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de proteina</p>
                </div>
            </div>
            <div className="nutricion2">
                <div>
                    <img src={Claim_colesterol } alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de colesterol</p>
                </div>
            </div>
            <div className="nutricion3">
                <div>
                    <img src={Claim_fiber } alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de fibra</p>
                </div>
            </div>
            <div className="nutricion4">
                <div>
                    <img src={Claim_grasa_saturada } alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de grasas saturada</p>
                </div>
            </div>
        </div>
    </div>
    <div className="img_avenas_hojuelas">
        <img src={Hojuelas_natural} alt="hojuelas pronalce del sur" />
    </div>
</div>

<div className="avenas_hojuela_fresa">
    <div className="img_avenas_hojuelas">
        <img src={Hojuelas_fresa} alt="hojuelas pronalce del sur" />
    </div>
    <div className="txt_avenas_hojuelas">
        <h2>
            Avena en hojuelas, <br /> con Fresa
        </h2>
        <br />
        <h3>
            Presentaciones:
        </h3>
        <h3>
            <b>35g - 1000g</b>
        </h3>
        <button target="_blank" className="boton_avena" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
            <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
        </button>
        <div className="nutricion">
            <div className="nutricion1">
                <div>
                    <img src={Claim_Protein} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de proteina</p>
                </div>
            </div>
            <div className="nutricion2">
                <div>
                    <img src={Claim_colesterol} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de colesterol</p>
                </div>
            </div>
            <div className="nutricion3">
                <div>
                    <img src={Claim_fiber} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de fibra</p>
                </div>
            </div>
            <div className="nutricion4">
                <div>
                    <img src={Claim_grasa_saturada} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de grasas saturada</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="tlt_hojuela">
    <h1>
        El mejor sabor natural
    </h1>
</div>

<div className="avenas_hojuela_banano">
    <div className="txt_avenas_hojuelas">
        <h2>
            Avena en hojuelas, <br /> con Banano
        </h2>
        <br />
        <h3>
            Presentaciones:
        </h3>
        <h3>
            <b>35g - 250g</b>
        </h3>
        <button target="_blank" className="boton_avena" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
            <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
        </button>
        <div className="nutricion">
            <div className="nutricion1">
                <div>
                    <img src={Claim_Protein} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de proteina</p>
                </div>
            </div>
            <div className="nutricion2">
                <div>
                    <img src={Claim_colesterol} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de colesterol</p>
                </div>
            </div>
            <div className="nutricion3">
                <div>
                    <img src={Claim_fiber} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de fibra</p>
                </div>
            </div>
            <div className="nutricion4">
                <div>
                    <img src={Claim_grasa_saturada} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de grasas saturada</p>
                </div>
            </div>
        </div>
    </div>
    <div className="img_avenas_hojuelas">
        <img src={ Hojuelas_banano} alt="hojuelas pronalce del sur" />
    </div>
</div>

<div className="avenas_hojuela_vainilla">
    <div className="img_avenas_hojuelas">
        <img src={Hojuelas_vainilla} alt="hojuelas pronalce del sur" />
    </div>
    <div className="txt_avenas_hojuelas">
        <h2>
            Avena en hojuelas, <br /> sabor Vainilla
        </h2>
        <br />
        <h3>
            Presentaciones:
        </h3>
        <h3>
            <b>1000g</b>
        </h3>
        <button target="_blank" className="boton_avena" onClick={() => window.location.href='https://pronalce.com.co/tiendaonline/'}>
            <i className="fa-solid fa-circle-play fa-beat" style={{ color: '#2b579e' }}></i> Ver mas...
        </button>
        <div className="nutricion">
            <div className="nutricion1">
                <div>
                    <img src={Claim_Protein} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de proteina</p>
                </div>
            </div>
            <div className="nutricion2">
                <div>
                    <img src={Claim_colesterol} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de colesterol</p>
                </div>
            </div>
            <div className="nutricion3">
                <div>
                    <img src={Claim_fiber}  alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p><b>Excelente</b> <br /> fuente de fibra</p>
                </div>
            </div>
            <div className="nutricion4">
                <div>
                    <img src={Claim_grasa_saturada} alt="hojuelas pronalce del sur" />
                </div>
                <div>
                    <p>Naturalmente es libre de grasas saturada</p>
                </div>
            </div>
        </div>
    </div>
</div>




        </div>
    
    );
};

export default Hojuelas;
import React, { useEffect } from "react";
import "./error_404.css";
import autoImage from "../../design/images/ezgif.com-gif-maker.gif";
import { NavLink } from "react-router-dom";

const Error_404 = () => {
    useEffect(() => {
        const auto = document.getElementById("auto");
        const contenedor = document.querySelector(".contenedor_auto");

        function reiniciarAnimacion() {
            auto.style.transition = "none";
            auto.style.transform = "translateX(0)";
            void auto.offsetWidth;
            auto.style.transition = "transform 9s linear";
            animarAuto();
        }

        function animarAuto() {
            auto.style.transform = "translateX(" + (contenedor.offsetWidth + 350) + "px)";
        }

        auto.addEventListener("transitionend", reiniciarAnimacion);

        animarAuto();

        return () => {
            auto.removeEventListener("transitionend", reiniciarAnimacion);
        };
    }, []);

    return (
        <div>
            <div className="grid-404">
                <div className="error404">
                    <i className="fa-solid fa-triangle-exclamation fa-bounce" style={{ color: "#ff0000" }}></i>
                    <h1>¡Error 404!</h1>
                    <p>La página que estás buscando no se encuentra.</p>
                    <NavLink to="/">Volver a la página principal</NavLink>
                </div>

                <div className="contenedor_auto">
                    <img src={autoImage} id="auto" alt="" />
                </div>
            </div>
        </div>
    );
};

export default Error_404;
